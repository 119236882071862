import * as React from 'react';
import { Button, PlayImage, Text } from './styles';
import PLAY_IMAGE from './play.png';

const PlayButton = ({ absolute, onClick }) => {
  return (
    <Button absolute={absolute} onClick={onClick}>
      <PlayImage src={PLAY_IMAGE} alt="Play" />
      <Text>Play</Text>
    </Button>
  );
};

export default PlayButton;
