import styled from 'styled-components';
import { COLORS } from '../../utils/theme';

export const Button = styled.div`
  cursor: pointer;
  background-color: white;
  width: 124px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  pointer-events: none;

  ${(props) =>
    props.absolute &&
    `
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `}
`;

export const PlayImage = styled.img`
  height: 12.63px;
  width: 14.58px;
  margin-right: 10px;
`;

export const Text = styled.div`
  color: ${COLORS.BLACK};
`;
